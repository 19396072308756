import React, { useCallback,useContext, useState, useMemo , useRef,useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JSON from 'JSON';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './employeeList.css'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { currencyFormatter,formatNumber } from '../utils/formatter';
import { getPreviousMonthAndYear } from '../utils/dateUtils';
import { exportToCsv } from '../utils/exportToCsv';
import { exportToPdf } from '../utils/exportToPdf';
import { calculateSum } from '../utils/calculateSum';

import { style } from '@mui/system';

export const Commissions = (param) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData]=useState(); 
  const [totalGP,setTotalGP]=useState(0.0);
  const [totalBill,setTotalBill]=useState(0.0);
  const [totalHours,setTotalHours]=useState(0);
  const [recruiting_gp,setRecruiting_gp]=useState(0);
  const [recruiting_gp_perm,setRecruiting_gp_perm]=useState(0);
  const [account_management_gp,setAccount_management_gp]=useState(0);
  const [account_management_gp_perm,setAccount_management_gp_perm]=useState(0);
  const [recruiter_commission,setRecruiter_commission]=useState(0);
  const [account_manager_commission,setAccount_manager_commission]=useState(0);
  const [total_commission,setTotal_commission]=useState(0);
  const [sales_gp,setSales_gp]=useState(0);
  const [sales_gp_perm,setSales_gp_perm]=useState(0);
  const [sales_commission,setSales_commission]=useState(0);
  const [showSummary,setShowSummary]=useState(true);
  var access_token = localStorage.getItem('access_token');
  const headers = {
    "Authorization": "Bearer "+ access_token
  };
  var resp, data;
  const gridRef = useRef();
  const [month,setMonth]=useState(getPreviousMonthAndYear(new Date()).prevMonth+1);
  const [year,setYear] = useState(getPreviousMonthAndYear(new Date()).prevYear);
  const [commissionPerson,setCommissionPerson] = useState(1); // SEQ is 1 
  const [commissionPersonsList,setCommissionPersonsList] = useState([]);
  const [showGrid,setShowGrid] = useState(true);

  
  const onGridReady = (params) => {
    axios.get(process.env.REACT_APP_API_URL+'/commissionPersonsList/',{headers:headers})
      .then(response => {
        resp = JSON.stringify(response.data);
        data = JSON.parse(resp);
        setCommissionPersonsList(data);
      }).catch((error)=>{  
            console.log(error);
      });

    axios.get(process.env.REACT_APP_API_URL+'/commissions/'+String(month)+"/"+String(year)+"/"+commissionPerson,
      {headers:headers}).then(response =>
    {
     resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
        setRecruiting_gp(data[0].recruiting_gp);
        setRecruiting_gp_perm(data[0].recruiting_gp_perm);
        setAccount_management_gp(data[0].account_management_gp);
        setAccount_management_gp_perm(data[0].account_management_gp_perm);
        setRecruiter_commission(data[0].recruiter_commission);
        setAccount_manager_commission(data[0].account_manager_commission);
        setTotal_commission(data[0].total_commission);
        setSales_gp(data[0].sales_gp);
        setSales_gp_perm(data[0].sales_gp_perm);
        setSales_commission(data[0].sales_commission);

     var data_details=data.slice(1,data.length);
     setRowData(data_details);
     setTotalGP(calculateSum(data_details.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data_details.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data_details.map(function(item){return item.total_bill;})));
     
    }
  ).catch((error)=>{
    console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
  }); 
    
    setGridApi(params.api);
  };

  
  const months = [{value:0,key:'All Months'},
                  {value:1, key:'January'},
                  {value:2 , key:'February'},
                  {value:3, key:'March'},
                  {key:'April',value:4},{key:'May',value:5},{key:'June',value:6},{key:'July',value:7},
                   {key:'August',value:8},{key:'September',value:9},{key:'October',value:10},
                   {key:'November',value:11},{key:'December',value:12}];
  const years = [
                 {value:2022},
                 {value:2023},
                 {value:2024},
                 {value:2025}];

  const [columnDefs] = useState([
    { field: 'entity',resizable: true ,headerName: 'Entity',sortable: true,filter: true},
       { field: 'ops_resource_first_name',resizable: true ,headerName: 'First',sortable: true,filter: true, 
        },
       { field: 'ops_resource_last_name',resizable: true,headerName:'Last',sortable: true,filter: true},
       { field: 'short_name',resizable: true,headerName:'Client',sortable: true,filter: true},
     //  { field: 'ops_resource_project_name',resizable: true,headerName:'Project',sortable: true,filter: true},
     //  { field: 'Month',resizable: true,headerName:'Month',sortable: true,filter: true},
     //  { field: 'Year',resizable: true,headerName:'Year',sortable: true,filter: true},
       { field: 'month_year',resizable: true,headerName:'Period',sortable: true,filter: true},
       { field: 'total_hours',resizable: true,headerName:'Hours',sortable: true},
       { field: 'hourly_bill_rate',resizable: true,headerName:'BillRate',
       valueFormatter: params => currencyFormatter(params.data.hourly_bill_rate),
       sortable: true},
       { field: 'hourly_pay_rate',resizable: true,headerName:'PayRate',
       valueFormatter: params => currencyFormatter(params.data.hourly_pay_rate),
       sortable: true},
       { field: 'exchange_rate',resizable: true,headerName:'XRate',
       valueFormatter: params => params.data.exchange_rate.toFixed(4),
       sortable: true},
       {field: 'spread',resizable: true,headerName:'Spread',
       valueFormatter: params => currencyFormatter(params.data.spread)},
       { field: 'monthly_gp',resizable: true,headerName:'GP($)',
       valueFormatter: params => currencyFormatter(params.data.monthly_gp),
       sortable: true},
       
       { field: 'recruiter_name',resizable: true,headerName:'Recruiter',sortable: true,filter: true},
      // { field: 'sourcer_name',resizable: true,headerName:'Sourcer',sortable: true,filter: true},
       { field: 'account_manager_name',resizable: true,headerName:'Acct Mgr.',sortable: true,filter: true},
       { field: 'sales_name',resizable: true,headerName:'Sales',sortable: true,filter: true}
       
   ]); 
   const indexColumn = {
    headerName: "#",
    field: "index",
    maxWidth: 120,
    cellRenderer: (params) => {
      // Use the rowIndex property to get the index of the row
      return params.rowIndex+1;
    }
  };
   const gridOptions = {
    
    onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: [indexColumn].concat(columnDefs),
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
   function onCellValueChanged(event) {
    console.log('data after changes is: ', event.data);

  }
  function reloadProjectsAndHours(){
    var access_token = localStorage.getItem('access_token');
    const headers = {
    "Authorization": "Bearer "+ access_token
    };
    setRowData([]);
    setShowGrid(false);
    var post_data={};
    axios.post(process.env.REACT_APP_API_URL+'/reloadProjectsAndHours',post_data,{headers:headers}).then(response =>{
      //console.log(response);
      getCommisionDetails();
    }).catch((error)=>{
      console.log(error);
       if(error.response.status === 401){
          localStorage.setItem('signedIn', 0);
          window.location.href = "/";
        } 
    });

  }

  function getCommisionDetails(){
    setRowData([]);
    setShowGrid(false);
    var access_token = localStorage.getItem('access_token');
     const headers = {
     "Authorization": "Bearer "+ access_token
    };
    axios.get(process.env.REACT_APP_API_URL+'/commissions/'+String(month)+"/"+String(year)+"/"+commissionPerson,
      {headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
     //console.log(data);
        setRecruiting_gp(data[0].recruiting_gp);
        setRecruiting_gp_perm(data[0].recruiting_gp_perm);
        setAccount_management_gp(data[0].account_management_gp);
        setAccount_management_gp_perm(data[0].account_management_gp_perm);
        setRecruiter_commission(data[0].recruiter_commission);
        setAccount_manager_commission(data[0].account_manager_commission);
        setTotal_commission(data[0].total_commission);
        setSales_gp(data[0].sales_gp);
        setSales_gp_perm(data[0].sales_gp_perm);
        setSales_commission(data[0].sales_commission);
     var data_details=data.slice(1,data.length);
     setRowData(data_details);
     setShowSummary(true);   
     setTotalGP(calculateSum(data_details.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data_details.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data_details.map(function(item){return item.total_bill;})));
     setShowGrid(true);
     
    }).catch(error => {
      console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    });
  }
  function exportData(){
    var yyyymmdd = new Date().toISOString().slice(0, 10);
    var person_name=commissionPersonsList.find(item => item.id === commissionPerson).first_name;

    gridApi.exportDataAsCsv({fileName:person_name+".Commision."+month+"."+year+"."+yyyymmdd+".csv"});
   // exportToCsv("Newcommision."+month+"."+year+"."+yyyymmdd+".csv",rowData);
   //exportToPdf();
  } 
  let summary;
  if(commissionPerson <=4 ){
    summary=(
       <div>
       <span style={{paddingLeft:'5px'}}>Total Bill(Contract): {currencyFormatter(totalBill)} </span>
       <span style={{paddingLeft:'5px'}}>Total Bill(Perm): {currencyFormatter(recruiting_gp_perm)}</span> 
       <span style={{paddingLeft:'5px'}}>Total Bill: {currencyFormatter(totalBill+recruiting_gp_perm)}</span> 
       <br></br>
       <span style={{paddingLeft:'5px'}}>Total GP(Contract): {currencyFormatter(totalGP)}</span>
       <span style={{paddingLeft:'5px'}}>Total GP(Perm): {currencyFormatter(recruiting_gp_perm)}</span>
       <span style={{paddingLeft:'5px'}}>Total GP: {currencyFormatter(totalGP+recruiting_gp_perm)} </span>
       <br></br>
       <span style={{paddingLeft:'5px'}}>Contract GP(%): {formatNumber(totalGP/totalBill*100,2)}</span>
       <span style={{paddingLeft:'5px'}}>All GP(%): {formatNumber((recruiting_gp_perm+totalGP)/(recruiting_gp_perm+totalBill)*100,2)}</span>
       <span style={{paddingLeft:'5px'}}>Avg. Bill Rate: {currencyFormatter(totalBill/totalHours)}</span>
       </div>
    ) }
    else {
        summary=(
            
            <div>
            <span style={{paddingLeft:'5px'}}>Recruiting GP (Contract): {currencyFormatter(recruiting_gp)}</span>
            <span style={{paddingLeft:'5px'}}>Recruiting GP (Perm): {currencyFormatter(recruiting_gp_perm)}</span>
            <span style={{paddingLeft:'5px'}}>Recruiting Commission: {currencyFormatter(recruiter_commission)}</span>
            <br></br>
            <span style={{paddingLeft:'5px'}}>Acct Mgr GP: {currencyFormatter(account_management_gp)}</span>
            <span style={{paddingLeft:'5px'}}>Acct Mgr GP (Perm): {currencyFormatter(account_management_gp_perm)}</span>         
            <span style={{paddingLeft:'5px'}}>Acct Mgr Commisson: {currencyFormatter(account_manager_commission)}</span>
            <br></br>
            <span style={{paddingLeft:'5px'}}>Sales GP: {currencyFormatter(sales_gp)}</span>
            <span style={{paddingLeft:'5px'}}>Sales GP (Perm): {currencyFormatter(sales_gp_perm)}</span>         
            <span style={{paddingLeft:'5px'}}>Sales Commisson: {currencyFormatter(sales_commission)}</span>
            <br></br>
            <span style={{paddingLeft:'5px'}}><b>Total Commission: {currencyFormatter(total_commission)}</b></span>
            </div>
         )   

    }
  function renderSummary(){
    if(showSummary==true){
      return (
        <div>
        {summary}
        </div>
      )
    }
  }

  function renderAGGrid(){
    
    if (showGrid == true) {
        return (
            <div style={{height: 800}} className="ag-theme-alpine">
       
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
        >
        </AgGridReact>
      </div>
        )
    }
    else {
        return (
        <div style={{height: 800}} className="ag-theme-alpine">    
        <LinearProgress/>
        </div>
        );
    }
   
    

    
  }
  const setPrinterFriendly = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '';
    eGridDiv.style.height = '';
    api.setDomLayout('print');
  };
  
  const setNormal = (api) => {
    const eGridDiv = document.querySelector('#myGrid');
    eGridDiv.style.width = '1400px';
    eGridDiv.style.height = '200px';
    api.setDomLayout();
  };
  const onBtPrint = useCallback(() => {
    console.log(gridRef);
    const api = gridRef.current.api;
    setPrinterFriendly(api);
    setTimeout(function () {
      window.print();
      setNormal(api);
    }, 2000);
  }, []);

 return (
    <div id="myGrid" >
    <style>
    {`@media print {.no-print{display: none;}}`}
   </style>
    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Month"
    value={month}
    select
    onChange={(e) => setMonth(e.target.value)} >
    {months.map((option) => (
      <MenuItem key={option.key} value={option.value}>
        {option.key}
      </MenuItem>
    ))}
     
    </TextField>

    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Year"
    value={year}
    select
    onChange={(e) => setYear(e.target.value)} >
    {years.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.value}
      </MenuItem>
    ))}
     
    </TextField>

    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Summary"
    value={commissionPerson}
    select
    onChange={(e) => {setCommissionPerson(e.target.value);setShowSummary(false);}} >
    {commissionPersonsList.map((option) => (
      <MenuItem key={option.first_name} value={option.id}>
        {option.first_name}
      </MenuItem>
    ))}
     
    </TextField>
    
    <Button className="no-print" variant="contained" style={{padding:'15px',
}}
        onClick={()=>getCommisionDetails()}
        >
        Submit
     </Button>
     <b> All GP and Commision numbers are in USD</b>
  

     <Button className="no-print" variant="contained" style={{position:'absolute',right:'125px',padding:'15px'}}
        onClick={onBtPrint}
        >
        Print
     </Button>
     <Button className="no-print" variant="contained" style={{position:'absolute',right:'25px',padding:'15px'}}
        onClick={exportData}
        >
        Export
     </Button>

     {renderSummary()}
     {renderAGGrid()}
     
    </div>
  
   );

};
