import React, { useContext, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import JSON from 'JSON';
import axios from 'axios';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import './employeeList.css'
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import LinearProgress from '@mui/material/LinearProgress';
import { currencyFormatter,formatNumber } from '../utils/formatter';
import { getPreviousMonthAndYear } from '../utils/dateUtils';
import { calculateSum } from '../utils/calculateSum';

export const EmployeeHours = (param) => {
  const [gridApi, setGridApi] = useState(null);
  const [rowData, setRowData]=useState(); 
  const [totalGP,setTotalGP]=useState(0.0);
  const [totalBill,setTotalBill]=useState(0.0);
  const [totalHours,setTotalHours]=useState(0);
  var access_token = localStorage.getItem('access_token');
  const headers = {
    "Authorization": "Bearer "+ access_token
  };
  var resp, data;
  
  const [month,setMonth]=useState(getPreviousMonthAndYear(new Date()).prevMonth+1);
  const [year,setYear] = useState(getPreviousMonthAndYear(new Date()).prevYear);

 
  
  const onGridReady = (params) => {
    console.log(month);
    axios.get(process.env.REACT_APP_API_URL+'/hours/'+param.entity+"/"+String(month)+"/"+String(year),
      {headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
     setRowData(data);
     setTotalGP(calculateSum(data.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data.map(function(item){return item.total_bill;})));
     console.log(rowData);  
    }
  ).catch((error)=>{
    console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
  }); 
    
    setGridApi(params.api);
  };

  const [showGrid,setShowGrid] = useState(true);
  
  const months = [{value:0,key:'All Months'},
                  {value:1, key:'January'},
                  {value:2 , key:'February'},
                  {value:3, key:'March'},
                  {key:'April',value:4},{key:'May',value:5},{key:'June',value:6},{key:'July',value:7},
                   {key:'August',value:8},{key:'September',value:9},{key:'October',value:10},
                   {key:'November',value:11},{key:'December',value:12}];
  const years = [
                 {value:2022},
                 {value:2023},
                 {value:2024},
                 {value:2025}];

  const [columnDefs] = useState([
       { field: 'ops_resource_first_name',resizable: true ,headerName: 'First',sortable: true,filter: true, 
        },
       { field: 'ops_resource_last_name',resizable: true,headerName:'Last',sortable: true},
       { field: 'short_name',resizable: true,headerName:'Client',sortable: true},
       { field: 'ops_resource_project_name',resizable: true,headerName:'Project',sortable: true},
       { field: 'Month',resizable: true,headerName:'Month',sortable: true},
       { field: 'Year',resizable: true,headerName:'Year',sortable: true},
       { field: 'total_hours',resizable: true,headerName:'Hours',sortable: true},
       { field: 'hourly_bill_rate',resizable: true,headerName:'BillRate',
       valueFormatter: params => currencyFormatter(params.data.hourly_bill_rate),
       sortable: true},
       { field: 'hourly_pay_rate',resizable: true,headerName:'PayRate',
       valueFormatter: params => currencyFormatter(params.data.hourly_pay_rate),
       sortable: true},
       { field: 'exchange_rate',resizable: true,headerName:'XRate',
       valueFormatter: params => params.data.exchange_rate.toFixed(4),
       sortable: true},
       { field: 'monthly_gp',resizable: true,headerName:'GP',
       valueFormatter: params => currencyFormatter(params.data.monthly_gp),
       sortable: true},
       
       { field: 'recruiter_name',resizable: true,headerName:'Recruiter',sortable: true},
       { field: 'sourcer_name',resizable: true,headerName:'Sourcer',sortable: true},
       { field: 'account_manager_name',resizable: true,headerName:'Acct Mgr.',sortable: true},
       { field: 'sales_name',resizable: true,headerName:'Sales',sortable: true}
       
   ]); 
   const indexColumn = {
    headerName: "#",
    field: "index",
    maxWidth: 120,
    cellRenderer: (params) => {
      //console.log(params);
      // Use the rowIndex property to get the index of the row
      return params.rowIndex+1;
    }
  };

   const gridOptions = {
    onCellValueChanged:onCellValueChanged,
    onGridReady:onGridReady,
    columnDefs: [indexColumn].concat(columnDefs),
    defaultColDef: {
      sortable: true,
      resizable: true,
      minWidth: 10,
      flex: 1,
    },
  };
   function onCellValueChanged(event) {
    console.log('data after changes is: ', event.data);

  }
  function reloadProjectsAndHours(){
    var access_token = localStorage.getItem('access_token');
    const headers = {
    "Authorization": "Bearer "+ access_token
    };
    setRowData([]);
    setShowGrid(false);
    var post_data={};
    axios.post(process.env.REACT_APP_API_URL+'/reloadProjectsAndHours',post_data,{headers:headers}).then(response =>{
      console.log(response);
      getCommisionDetails();
    }).catch((error)=>{
      console.log(error);
       if(error.response.status === 401){
          localStorage.setItem('signedIn', 0);
          window.location.href = "/";
        } 
    });

  }

  function getCommisionDetails(){
    setRowData([]);
    setShowGrid(false);
    var access_token = localStorage.getItem('access_token');
     const headers = {
     "Authorization": "Bearer "+ access_token
    };
    console.log(headers);
    console.log("Will get data for "+ String(month)+ "  and year "+ String(year));
    axios.get(process.env.REACT_APP_API_URL+'/hours/'+param.entity+"/"+String(month)+"/"+String(year),
      {headers:headers}).then(response =>
    {resp = JSON.stringify(response.data);
     data = JSON.parse(resp);
     setRowData(data);
     setTotalGP(calculateSum(data.map(function(item){return item.monthly_gp;})));
     setTotalHours(calculateSum(data.map(function(item){return item.total_hours;})));
     setTotalBill(calculateSum(data.map(function(item){
                                                        if(item.exchange_rate < 0.0){
                                                        return 0;
                                                        }
                                                        else {
                                                          return item.hourly_bill_rate*item.total_hours;
                                                        }
                                                        })));
     setShowGrid(true);
     console.log(rowData);
     
    }).catch(error => {
      console.log(error);
      if(error.response.status === 401){
        localStorage.setItem('signedIn', 0);
        window.location.href = "/";
      }
    });
  }
  function exportData(){
    var yyyymmdd = new Date().toISOString().slice(0, 10);
    gridApi.exportDataAsCsv({fileName:"commision."+month+"."+year+"."+yyyymmdd+".csv"});
  } 
  function renderAGGrid(){
    if (showGrid == true) {
        return (
            <div style={{height: 800}} className="ag-theme-alpine">
       
        <AgGridReact
          rowData={rowData}
          gridOptions={gridOptions}
          onGridReady={onGridReady}
        >
        </AgGridReact>
      </div>
        )
    }
    else {
        return (
        <div style={{height: 800}} className="ag-theme-alpine">    
        <LinearProgress/>
        </div>
        );
    }
  }
 return (
    <div>
    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Month"
    value={month}
    select
    onChange={(e) => setMonth(e.target.value)} >
    {months.map((option) => (
      <MenuItem key={option.key} value={option.value}>
        {option.key}
      </MenuItem>
    ))}
     
    </TextField>

    <TextField style={{paddingRight:'5px'}}
    id="outlined-name"
    label="Year"
    value={year}
    select
    onChange={(e) => setYear(e.target.value)} >
    {years.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.value}
      </MenuItem>
    ))}
     
    </TextField>
    <Button variant="contained" style={{padding:'15px'}}
        onClick={()=>getCommisionDetails()}
        >
        Submit
     </Button>
     
     <span style={{paddingLeft:'5px'}}><b>Total Bill: {currencyFormatter(totalBill)} </b></span>
     <span style={{paddingLeft:'5px'}}><b>Total GP: {currencyFormatter(totalGP)}</b> </span>
     <span style={{paddingLeft:'5px'}}><b>GP(%): {formatNumber(totalGP/totalBill*100,2)}</b></span>
     <span style={{paddingLeft:'5px'}}><b>Avg. Bill Rate: {currencyFormatter(totalBill/totalHours)}</b></span>
     <Button variant="contained" style={{position:'absolute',right:'125px',padding:'15px'}}
        onClick={()=>reloadProjectsAndHours()}
        >
        Reload Ops Data
     </Button>
 
     <Button variant="contained" style={{position:'absolute',right:'25px',padding:'15px'}}
        onClick={exportData}
        >
        Export
     </Button>

    
     {renderAGGrid()}
     
    </div>
  
   );

};
