import React from 'react';

export default (props) => {
  //console.log(props);

  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const url="/edit/"+props.entity+"/"+cellValue;
  
  return (
    <span>
      <a href={url}><button>Modify</button></a>
    </span> 
  );
};